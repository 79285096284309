







































































































































































































































































































































































import { Vue, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { mask } from 'vue-the-mask'
import i18n from '@/i18n'

@Component({
  layout: 'unAuthenticated',
  directives: {
    mask,
  },
})
export default class Order extends Vue {
  public $refs: Vue['$refs'] & {
    form: {
      validate: any // eslint-disable-line
    }
  }

  @Prop({ type: String, required: true })
  private manualToken: string

  private language = null
  private dateMask = '####-##-##'

  private pagination = {
    itemsPerPage: -1,
  }

  private defaultTracking = { trackingNumber: '', trackingUrl: '', shipmentDate: '', estimatedArrivalDate: '' }

  private saving = false
  private loadingData = true
  private loadingError = false
  private loadedData = false

  private productsHeaders = []

  private supplierProductsHeaders = []

  private trackingsHeaders = []

  private statusOptions = []

  private order = []
  private orderLines = []
  private orderLinesTotals = []
  private trackings = []

  private customerAddress = {}
  private deliveryAddress = {}
  private deliveryMode = null

  private token = null
  private isManual = false

  private created() {
    this.isManual = !!this.manualToken

    const q = this.$route.query

    if (q.language) {
      this.language = q.language.toString()
      if (this.language === 'nb') {
        this.dateMask = '##.##.####'
      }
    }

    i18n.setLanguage(this.language || this.$i18n.locale || 'sv')

    this.productsHeaders = [
      {
        text: this.$t('c:supplier-order-view:Supplier Product ID'),
        value: 'product_id',
      },
      {
        text: this.$t('c:supplier-order-view:Product'),
        value: 'product_text',
      },
      {
        text: this.$t('c:supplier-order-view:Comment'),
        value: 'comment',
      },
      {
        text: this.$t('c:supplier-order-view:License Plate'),
        value: 'license_plate',
      },
      { text: this.$t('c:supplier-order-view:Quantity'), value: 'quantity', hasTotal: true },
      { text: this.$t('c:supplier-order-view:Unit Price'), value: 'cost_exchanged', hasTotal: false },
      { text: this.$t('c:supplier-order-view:Currency'), value: 'currency', hasTotal: false },
      { text: this.$t('c:supplier-order-view:Total Price'), value: 'total_price', hasTotal: true },
    ]

    this.supplierProductsHeaders = [
      {
        text: this.$t('c:supplier-order-view:Supplier Product ID'),
        value: 'product_id',
      },
      {
        text: this.$t('c:supplier-order-view:Supplier Order ID'),
        value: 'supplier_order_id',
        sortable: false,
      },
      {
        text: this.$t('c:supplier-order-view:Status'),
        value: 'supplier_order_line_status',
        sortable: false,
      },
      {
        text: this.$t('c:supplier-order-view:Supplier comment'),
        value: 'supplier_order_line_messages',
        sortable: false,
      },
    ]

    this.trackingsHeaders = [
      {
        text: this.$t('c:supplier-order-view:Tracking Number'),
        value: 'trackingNumber',
        sortable: false,
      },
      { text: this.$t('c:supplier-order-view:Tracking URL'), value: 'trackingUrl', sortable: false },
      { text: this.$t('c:supplier-order-view:Shipment Date'), value: 'shipmentDate', sortable: false },
      {
        text: this.$t('c:supplier-order-view:Estimated Arrival Date'),
        value: 'estimatedArrivalDate',
        sortable: false,
      },
      { text: this.$t('c:supplier-order-view:Actions'), value: 'actions', sortable: false },
    ]

    this.getOrderDetails()
  }

  private getOrderDetails() {
    const q = this.$route.query

    this.token = q.token

    const url = this.manualToken
      ? '/v3/supplier/order?manual=' + this.manualToken
      : '/v3/supplier/order?token=' + this.token

    this.$axios
      .get(url)
      .then((response) => {
        this.order = response.data.data.order
        this.orderLines = response.data.data.orderLines
        this.customerAddress = response.data.data.customerAddress
        this.deliveryMode = response.data.data.deliveryMode
        this.deliveryAddress = response.data.data.deliveryAddress
        this.orderLinesTotals = response.data.data.orderLinesTotals
        this.statusOptions = response.data.data.statusOptions
        this.trackings = response.data.data.trackings

        if (this.trackings.length === 0 && !this.isManual) {
          this.trackings = [this.defaultTracking]
        }
        this.loadedData = true
      })
      .catch((err) => {
        err.response.data.errors.forEach((v, _i) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('Error') as string,
          })
        })
        this.loadingError = true
      })
      .finally(() => {
        this.loadingData = false
      })
  }

  private addTrackingNumber() {
    const clonedDefaultTracking = Object.assign({}, this.defaultTracking)
    this.trackings.push(clonedDefaultTracking)
  }

  private deleteTrackingNumber(item) {
    const index = this.trackings.indexOf(item)
    this.trackings.splice(index, 1)
  }

  private save() {
    if (this.$refs.form.validate()) {
      this.saving = true
      const data = {
        orderLines: this.orderLines,
        trackings: this.trackings,
      }

      const url = this.manualToken
        ? '/v3/supplier/order?manual=' + this.manualToken
        : '/v3/supplier/order?token=' + this.token
      this.$axios
        .post(url, data)
        .then((_response) => {
          vxm.alert.info({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Saved') as string,
          })

          if (this.isManual) {
            // NOTE: Inform legacy frontend
            this.$emit('update')
          } else {
            this.getOrderDetails()
          }
        })
        .catch((err) => {
          err.response.data.errors.forEach((v, _i) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
